import React, { useEffect, useState } from 'react';

import { Button, Modal, Form, Spinner } from 'react-bootstrap';

import { CloseOutlined } from '@ant-design/icons';

import axios from "axios"

import "./App.scss";


const icons = require("@ant-design/icons");

function App({
  icon,
  title,
  description,
  proyecto_id,

  modalTitle = "",
  modalNumber = "",

  buttonProps = {},
  modalClassName = "",

  launchModal
}) {


  let [visible, setVisible] = React.useState(false);
  let [loading, setLoading] = React.useState(false);
  const [validated, setValidated] = useState(false);


  useEffect(() => {

    let setVisibleTrue = () => setVisible(true)

    let elements = []

    if (launchModal)
      elements = document.querySelectorAll(launchModal)
    elements.forEach(element => element.addEventListener("click", setVisibleTrue))
    
    console.log("elements", elements)
    return () => {
      elements.forEach(element => element.removeEventListener("click", setVisibleTrue))
    }
    
  }, []);


  const handleSubmit = (event) => {
    console.log("1");
    event.preventDefault();
    event.stopPropagation();

    console.log("2");
    const form = event.currentTarget;

    window.ninin = form
    console.log("3");
    if (form.checkValidity() === false)
      return setValidated(false);

    setValidated(true);

    let data = new FormData(form)
    data.append("proyecto_id", proyecto_id);
    setLoading(true);
    axios.post("/api/contact", data)
      .then(({ data }) => {
        // console.log(res);
        setLoading(false);
        setVisible(false);
      })
      .catch(error => {
        alert("Error al enviar el formulario");
      })
      .finally(() => {
        setLoading(false);
      })


  };

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => setVisible(!visible)}

      >
        <img src={icon} />
        <h6>{title}</h6>
        <p>{description}</p>
      </Button>
      <Modal
        size='lg'
        className={"hm-modal-contact " + modalClassName}
        centered
        show={visible} onHide={() => setVisible(!visible)}>

        <Modal.Header style={{ position: "relative" }}>
          <Modal.Title>{modalTitle}</Modal.Title>
          <Modal.Title className="text-title">{modalNumber}</Modal.Title>

          <CloseOutlined
            onClick={() => !loading ? setVisible(!visible) : null}
            style={{
              position: 'absolute',
              color: "white",
              top: "-50px",
              right: "-24px"
            }} />
        </Modal.Header>
        <Modal.Body>
          <Form
            id="form-hm-contact-proceed"
            name="form-hm-contact-proceed"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3" controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control required type="text" placeholder="John Doe" name='nombre' />
              <Form.Control.Feedback type="invalid">Debe de indicar el nombre</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="email" >
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="jdoe@example.com" required name='email' />
              <Form.Control.Feedback type="invalid">Debe de indicar el correo electrónico</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="telefono">
              <Form.Label>Telefono</Form.Label>
              <Form.Control type="tel" placeholder="55 1916 4022" required name='telefono' />
              <Form.Control.Feedback type="invalid">Debe de indicar el número telefónico.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="comentario">
              <Form.Label>Mensaje</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Puede indicar cualquier duda o comentario." name='comentario' />
            </Form.Group>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!!loading} variant="primary" form='form-hm-contact-proceed' key='reset' htmltype='reset' type='reset'>
            Restablecer
          </Button>
          <Button disabled={!!loading} variant="secondary" form='form-hm-contact-proceed' key='submit' htmltype='submit' type='submit'>
            {loading ? <Spinner animation="border" role="status" className='hm-spinner' /> : null}
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default App;
