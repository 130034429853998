import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import axios from 'axios';


axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
window.HMWidget = class HMWidget {


  constructor(options) {
    this.options = options


    this.render()
  }
  render() {
    // 'humanistics-contact-form'
    const root = ReactDOM.createRoot(document.getElementById(this.options?.id))

    root.render(
      <React.StrictMode>
        <div className='humanistics-form-contact-widget'>
          <App  {...this.options} />
        </div>
      </React.StrictMode>
    );
  }
}

// const HMWidget = window.HMWidget
// const m = new HMWidget({
//   id: "humanistics-contact-form",

//   proyecto_id: "6160f45b00347df7d034b87d",
  
//   title: "AGENDA UNA CITA",
//   description: "55 1009 2155",
//   icon: "/img/a.svg",

//   modalTitle: "Indique su información",
//   modalNumber: "55 1009 2155",
//   buttonProps: {},
//   launchModal: ".generate"
// })

reportWebVitals();
